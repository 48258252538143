<!-- =========================================================================================
  Author: Nada Azzam
========================================================================================== -->

<template>
  <div id="data-list">
    <vs-popup
      classContent="popup-example"
      title="Confirm Update"
      :active.sync="popupActive"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <ValidationProvider
            name="password"
            rules="required|min:8"
            v-slot="{ errors }"
          >
            <vs-input
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              type="password"
              name="password"
              icon-no-border
              icon="icon icon-lock"
              icon-pack="feather"
              label-placeholder="Password"
              v-model="password"
              class="w-full mt-6"
            />
          </ValidationProvider>

          <vs-button
            :disabled="invalid"
            type="filled"
            @click="update"
            class="mb-2 mt-5"
            >Confirm</vs-button
          >
        </form>
      </validation-observer>
    </vs-popup>
    <vx-card
      ref="filterCard"
      title="Adding New Roles"
      class="user-list-filters mb-8"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <div class="vx-row">
            <div class="vx-col md:w- sm:w- w-full mb-2">
              <div class="vx-row">
                <div class="vx-col w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>Roles NAME
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="name"
                    rules="required|min:2"
                  >
                    <vs-input
                      class="w-full"
                      icon-pack="feather"
                      icon="icon-terminal"
                      icon-no-border
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                      v-model="name"
                    />
                  </validation-provider>
                </div>

                <div class="vx-col w-full my-4">
                  <vs-row vs-justify="center" class="pt-5">
                    <vs-col
                      type="flex"
                      vs-justify="left"
                      vs-align="left"
                      vs-w="6"
                    >
                      <div class="vx-card__title">
                        <h3 class="py-5">Permissions</h3>
                        <!---->
                      </div>
                    </vs-col>
                    <vs-col
                      class="m-auto"
                      type="flex"
                      vs-justify="right"
                      vs-align="right"
                      vs-w="6"
                    >
                      <vs-checkbox
                        style="justify-content: flex-end !important"
                        class="py-2"
                        vs-justify="right"
                        vs-align="right"
                        @click="selectAll"
                        v-model="allSelected"
                        >Select All</vs-checkbox
                      >
                    </vs-col>
                  </vs-row>

                  <!-- GRID PERMISSIONS LIST -->
                  <vs-row vs-justify="left" class="pt-5">
                    <vs-col
                      type="flex"
                      vs-justify="left"
                      vs-align="left"
                      vs-w="6"
                      v-for="(arrPer, namePer) in permissions"
                      :key="namePer"
                    >
                      <div class="vx-card__title">
                        <h4 class>{{ arrPer.group_name }}</h4>
                        <!---->
                      </div>
                      <vs-card class="cardx" :fixedHeight="false">
                        <vs-row vs-justify="left">
                          <vs-col
                            v-for="(per, index) in arrPer.permissions"
                            :key="per + index"
                            type="flex"
                            vs-justify="left"
                            vs-align="left"
                            vs-w="6"
                          >
                            <vs-checkbox
                              class="py-2"
                              :checked="permission.indexOf(per.name) != -1"
                              :id="namePer + arrPer.group_name"
                              :vs-value="per.name"
                              v-model="permission"
                              @change="togglePermission(per.name)"
                              >{{ per.name }}</vs-checkbox
                            >
                          </vs-col>
                        </vs-row>
                      </vs-card>
                    </vs-col>
                  </vs-row>
                </div>
                <div class="vx-col w-full my-5 pt-5">
                  <vs-button
                    :disabled="invalid"
                    type="filled"
                    @click="openPopUp()"
                    class="mb-2"
                    >SUBMIT</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
        </form>
      </validation-observer>
    </vx-card>
  </div>
</template>

<script>
import axios from "@/axios.js";
import endpoints from "@/endpoints";
import { required, min } from "vee-validate/dist/rules";
import moduleRolesManagement from "@/store/get-all-roles/moduleRolesManagement.js";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty",
});

extend("min", {
  ...min,
  message: "field may not be less than {length} characters",
});

// Store Module

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      name: null,
      popupActive: false,
      password: null,
      allSelected: false,

      permission: [],
    };
  },

  computed: {
    permissions() {
      return this.$store.state.roles.permissions;
    },
  },
  methods: {
    openPopUp() {
      this.popupActive = true;
    },
    togglePermission(per) {
      if (this.permission.indexOf(per) != -1) {
        let index = this.permission.findIndex((e) => e === per);
        this.permission.splice(index, 1);
      } else {
        this.permission.push(per);
      }

      // console.log( this.permission);
    },

    resetColFilters(card) {
      card.removeRefreshAnimation(100);
      this.category_name = null;
    },
    update() {
      if (!this.permission) {
        this.$vs.notify({ color: "danger", title: "Permission is Required" });
      } else {
        this.$vs.loading();
        const edit = {
          name: this.name,
          permissions: this.permission,
          password: this.password,
          _method: "put",
        };
        this.popupActive = false;

        axios
          .post(`${endpoints.ROLES_ENDPOINT}/${this.$route.query.SID}`, edit)
          .then((response) => {
            this.$vs.loading.close();
            this.popupActive = false;

            if (response.status !== 200) {
              this.$vs.notify({
                title: "Error",
                text: response.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
              this.popupActive = false;
            } else {
              this.$vs.notify({
                title: "Success",
                text: response.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.$router.push("/GetAll/Roles");
              this.popupActive = false;

              // check user has role and set permission for localStorage
              let userLogin = JSON.parse(localStorage.getItem("userInfo"));
              if (userLogin.roles.join() == this.name) {
                this.$store.commit("auth/SET_PERMISSIONS", this.permission);
                localStorage.setItem(
                  "permissions",
                  JSON.stringify(this.permission)
                );
                //  window.location.reload()
                location.reload();
              }
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.popupActive = false;

            this.$vs.notify({
              title: "Error",
              text: error.response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      }
    },
    goToCompanyList() {
      this.$router.push("/GetAll/Roles");
    },
    getById() {
      this.$vs.loading();

      this.$store
        .dispatch("roles/getById", this.$route.query.SID)
        .then((response) => {
          this.permission = response.data.permissions.map((e) => e.name);
          this.name = response.data.name;

          this.$vs.loading.close();
          if (response.data.status === 404) {
            this.$vs.notify({
              title: "Error",
              text: "Make sure select the main filters ",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
    },
    selectAll() {
      this.permission = [];

      if (!this.allSelected) {
        this.permissions.forEach((element) => {
          element.permissions.forEach((per) => {
            this.permission.push(per.name);
          });
        });
      }
      // console.log(this.permission);
    },
    getAllPermissions() {
      this.$vs.loading();

      this.$store.dispatch("roles/getPermissions").then((response) => {
        this.$vs.loading.close();
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
  },

  mounted() {
    this.getAllPermissions();
    this.getById();
  },
  created() {
    if (!moduleRolesManagement.isRegistered) {
      this.$store.registerModule("roles", moduleRolesManagement);
      moduleRolesManagement.isRegistered = true;
    }
  },
};
</script>

<style >
.cardx {
  width: calc(88% - 10px);
  margin: 5px;
}
.centerx li {
  margin: 8px 0;
}
</style>